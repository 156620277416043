import {React,useEffect,useRef,useState} from 'react'
import '../Scan.css'
import inst_video_1 from "../video/inst_video.webm";
import inst_video_2 from "../video/inst_video.mp4";
import Vitals from './Vitals';

function Scan() {

    const [hideVideo,sethideVideo] =  useState(false);
    const [hideSkip,sethideSkip] = useState(false);
    const vidRef = useRef(null);
    const [fpsStarted,setStartedFps]=useState(false)
    const handleStopVideo = ()=>{
        vidRef.current.pause();
    }
    const scanVidRef = useRef(null);
    useEffect(()=>{
        if(scanVidRef.current){
            scanVidRef.current.playsInline = true;
            scanVidRef.current.muted = true;
        }
    },[scanVidRef])
    const iOS = !!window.navigator.platform && /iPad|iPhone|iPod/.test(window.navigator.platform);
    const handleStartedFps = ()=>{
        setStartedFps(true);
    }
  return (
    <div className='app_container'>
    <div className='body'>
        <h2 id="checker">Modules Loading</h2>
        <div>
            <p className='version'>version-3.0.221205</p>
            <p className='fps'>FPS : <span id='fps_msg'></span></p>
        </div>
        <div className="webcam__container">
            <div className='skip__container'>
                <div id='skip__indication' hidden onClick={handleStartedFps}></div>
                {fpsStarted ? (
                    <div>
                        {hideSkip ? (
                        <button id='skip__btn' className="" onClick={()=>{sethideVideo(true);sethideSkip(true);handleStopVideo();}} hidden={hideSkip}>Skip Video</button>
                        ): (
                        <button id='skip__btn' className="skip__btn" onClick={()=>{sethideVideo(true);sethideSkip(true);handleStopVideo();}} hidden={hideSkip}>Skip Video</button>
                        )}
                    </div>
                    
                ): (
                    <div hidden className=''>
                        {hideSkip ? (
                        <button id='skip__btn' className="" onClick={()=>{sethideVideo(true);sethideSkip(true);handleStopVideo();}} hidden={hideSkip}>Skip Video</button>
                        ): (
                        <button id='skip__btn' className="skip__btn" onClick={()=>{sethideVideo(true);sethideSkip(true);handleStopVideo();}} hidden={hideSkip}>Skip Video</button>
                        )} 
                    </div>
                )
                }
            </div>
            <div className="inst__container">
                <video ref={vidRef} autoPlay loop muted playsInline controls hidden={hideVideo} className="inst__video" >
                    {
                        iOS ?
                        (
                            <source src={inst_video_2} type="video/mp4"></source>
                        ) :
                        (
                            <source src={inst_video_1} type="video/webm"></source>
                        )
                    }
                </video>
            </div>
            <video hidden id="video" width="640" height="480" ref={scanVidRef}></video>
            
            <div className="webcam__video">
                <div className='progress__container'>
                    <div id="progress__value"></div>
                </div>
                <canvas id = "canvas" width="640" height="480"></canvas>
            </div>
            <div className='instruction__box'>
                <p>Please look directly into the camera during scanning</p>
            </div>
            <div className="button__container">
                <button id='start_stop_btn' value="1" className="start__button">Start Capture</button>
            </div>
        </div>
        <div className="info__container">
            <p className='info__message'>Message : <span className="info__value" id='prog_dynamic'></span></p>
        </div>
        <Vitals/>
    </div>
    </div>
    
  )
}

export default Scan
